import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import { Avatar } from '@twilio-paste/avatar';
import Changelog from '@twilio-paste/avatar/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
import { UserIcon } from '@twilio-paste/icons/esm/UserIcon';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/avatar"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/avatar/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/avatar" storybookUrl="/?path=/story/components-avatar--initials" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for you to use in production. Components such as Avatar are likely to not see any API
    changes. This is only an alpha due to the lack of documentation and a matching Figma component. Feel free to share
    your experience using this component by{' '}
    <Anchor href="https://github.com/twilio-labs/paste/discussions/new" mdxType="Anchor">starting a discussion on GitHub</Anchor>. We are
    also looking for a contributor to symbolize this component in Figma. Interested? Reach out!
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Avatar`}</h3>
        <p>
  {props.pageContext.frontmatter.description} The Avatar can be sized in the same way as the{' '}
  <a href="/icons/usage-guidelines/#resizing-icons">Paste Icon</a>.
        </p>
        <h2>{`Examples`}</h2>
        <h3>{`Representing an entity via their initials`}</h3>
        <p>{`Provide the Avatar with a `}<inlineCode parentName="p">{`name`}</inlineCode>{` and the component will render the initials as their visual representation. The option is particularly useful if there is no supplied image.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">A note about size 10 and 20</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    On the 2 smallest sizes some initials will get cut off if the characters are particularly wide, such as W. Although
    we do support them due to maintaining size parity with Icons, we do not recommend using these 2 sizes.
  </CalloutText>
        </Callout>
        <LivePreview scope={{
          Avatar,
          Stack
        }} mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space40">
  <Avatar size="sizeIcon30" name="Trayvon Martin" />
  <Avatar size="sizeIcon40" name="Breonna Taylor" />
  <Avatar size="sizeIcon50" name="Shantel Davis" />
  <Avatar size="sizeIcon60" name="Walter Scott" />
  <Avatar size="sizeIcon70" name="James Baldwin" />
  <Avatar size="sizeIcon80" name="Toni Morrison" />
  <Avatar size="sizeIcon90" name="Chimamanda Ngozi Adichie" />
  <Avatar size="sizeIcon100" name="Sarah Li" />
  <Avatar size="sizeIcon110" name="Katie Porter" />
</Stack>`}
        </LivePreview>
        <h3>{`Representing an entity via an image`}</h3>
        <p>{`Provide the Avatar with a source path via the `}<inlineCode parentName="p">{`src`}</inlineCode>{` prop to render the Avatar as an image. The `}<inlineCode parentName="p">{`src`}</inlineCode>{` prop acts just like an `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag. You `}<strong parentName="p">{`must`}</strong>{` still provide a `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop.`}</p>
        <LivePreview scope={{
          Avatar,
          Stack
        }} mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space40">
  <Avatar size="sizeIcon30" name="avatar example" src="/images/avatars/avatar4.png" />
  <Avatar size="sizeIcon40" name="avatar example" src="/images/avatars/avatar1.png" />
  <Avatar size="sizeIcon50" name="avatar example" src="/images/avatars/avatar2.png" />
  <Avatar size="sizeIcon60" name="avatar example" src="/images/avatars/avatar3.png" />
  <Avatar size="sizeIcon70" name="avatar example" src="/images/avatars/avatar4.png" />
  <Avatar size="sizeIcon80" name="avatar example" src="/images/avatars/avatar1.png" />
  <Avatar size="sizeIcon90" name="avatar example" src="/images/avatars/avatar2.png" />
  <Avatar size="sizeIcon100" name="avatar example" src="/images/avatars/avatar3.png" />
  <Avatar size="sizeIcon110" name="avatar example" src="/images/avatars/avatar4.png" />
</Stack>`}
        </LivePreview>
        <h3>{`Representing an entity via an icon`}</h3>
        <p>{`Provide the Avatar with an `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop to display an icon. You must import the icon before passing it to the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop. You `}<strong parentName="p">{`must`}</strong>{` still provide a `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop. The icon must be a `}<a href="https://paste.twilio.design/icons/">{`Paste Icon`}</a>{`.`}</p>
        <LivePreview scope={{
          Avatar,
          Stack,
          UserIcon
        }} mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space40">
  <Avatar size="sizeIcon30" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon40" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon50" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon60" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon70" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon80" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon90" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon100" name="avatar example" icon={UserIcon} />
  <Avatar size="sizeIcon110" name="avatar example" icon={UserIcon} />
</Stack>`}
        </LivePreview>
        <h3>{`Responsive sizing`}</h3>
        <p>{`The Avatar `}<inlineCode parentName="p">{`size`}</inlineCode>{` can be set as a responsive array of sizes.`}</p>
        <LivePreview scope={{
          Avatar,
          Stack,
          UserIcon
        }} mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space40">
  <Avatar
    size={["sizeIcon20", "sizeIcon70", "sizeIcon110"]}
    name="Toni Morrison"
  />
  <Avatar
    size={["sizeIcon20", "sizeIcon70", "sizeIcon110"]}
    name="avatar example"
    src="/images/avatars/avatar4.png"
  />
  <Avatar
    size={["sizeIcon20", "sizeIcon70", "sizeIcon110"]}
    name="avatar example"
    icon={UserIcon}
  />
</Stack>`}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-circle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-user`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight-bold`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Size Token Mapping`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Size`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Font Size`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Line Height`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Icon Size`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-10`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-10`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-10`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-40`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-40`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-10`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-20`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-60`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-60`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-20`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-30`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-80`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-80`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-40`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-90`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-40`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-90`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-50`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-100`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-60`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-100`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-70`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-110`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-size-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`line-height-110`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-icon-80`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/avatar - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Avatar} from '@twilio-paste/avatar';

const AvatarExample = () => {
  return <Avatar size="sizeIcon10" name="Aayush Iyer" />;
};
`}</code></pre>
        <p><strong parentName="p">{`Note:`}</strong>{` The Avatar component will display the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` if both the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` and `}<inlineCode parentName="p">{`src`}</inlineCode>{` props are passed.`}</p>
        <h4>{`Props`}</h4>
        <h5><inlineCode parentName="h5">{`name`}</inlineCode>{` string`}</h5>
        <p>{`Provide the name of the user or object.`}</p>
        <h5><inlineCode parentName="h5">{`size`}</inlineCode>{` IconSize[]`}</h5>
        <p>{`Responsive size property that takes IconSize token names.`}</p>
        <h5><inlineCode parentName="h5">{`src`}</inlineCode>{` string`}</h5>
        <p>{`Used to set the image `}<inlineCode parentName="p">{`src`}</inlineCode>{` attribute when setting an image as the Avatar.`}</p>
        <h5><inlineCode parentName="h5">{`icon`}</inlineCode>{` Paste Icon`}</h5>
        <p>{`Specify a Paste Icon to be displayed in the Avatar.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      